<template>
    <v-app theme="dark">
        <v-layout>
            <v-app-bar
                id="home"
                height="auto"
                color="transparent"
                :elevation="0"
            >
                <v-container
                    class="d-flex justify-space-between align-center"
                    style="max-width: 1320px"
                >
                    <div>
                        <a href="#home" class="d-flex align-center">
                            <img src="bizsimlogolong.webp" height="80" />
                        </a>
                    </div>
                    <div class="r-hide">
                        <a href="#">
                            <v-btn color="white">Home</v-btn>
                        </a>
                        <a href="#games">
                            <v-btn class="ms-1" color="white">Games</v-btn>
                        </a>
                        <a href="#team">
                            <v-btn class="ms-1" color="white">Team</v-btn>
                        </a>
                        <a href="#aboutus">
                            <v-btn class="ms-1" color="white">About Us</v-btn>
                        </a>
                        <a href="#contact">
                            <v-btn class="ms-1" color="white">Contact</v-btn>
                        </a>
                    </div>
                </v-container>
            </v-app-bar>
            <v-main>
                <section class="bs-home-wrapper">
                    <vue-particles
                        style="height: 100vh"
                        color="#fff"
                        :particleOpacity="0.7"
                        :particlesNumber="150"
                        shapeType="circle"
                        :particleSize="3"
                        linesColor="#fff"
                        :linesWidth="1"
                        :lineLinked="false"
                        :lineOpacity="0.4"
                        :linesDistance="150"
                        :moveSpeed="1"
                        :hoverEffect="false"
                        hoverMode="grab"
                        :clickEffect="false"
                        clickMode="push"
                    >
                    </vue-particles>
                    <div class="bs-home">
                        <v-container
                            class="d-flex align-center"
                            style="max-width: 1320px"
                        >
                            <v-row no-gutter>
                                <v-col
                                    class="d-flex justify-start align-center home-content-col"
                                >
                                    <div>
                                        <h1 class="text-h4 text-secondary mb-4">
                                            Business Games
                                        </h1>
                                        <p class="text-body-2">
                                            Welcome to the ultimate destination for Smart Games for Smart Brains, where innovation meets intellect in the realm of business. Our uniquely crafted <strong>Business Games</strong> and <strong>Tycoon Games</strong> are designed not just for entertainment, but as a sophisticated platform for strategic thinking and cognitive challenge. With a focus on <strong>Business Simulation</strong> and <strong>Company Simulation</strong>, we offer an immersive gaming experience that simulates real-world economic, management, and entrepreneurial challenges.
                                        </p>
                                        <p class="text-body-2">
                                            Dive into the depths of complex business landscapes, where every decision counts and every strategy shapes your path to success. Our games are tailored for those who thrive on intellectual stimulation, offering a perfect blend of complexity and enjoyment. Whether you're aspiring to be a business mogul in the virtual world or seeking to sharpen your strategic skills, our games serve as the ultimate brain-training playground.
                                        </p>
                                        <p class="text-body-2">
                                            Smart Games for Smart Brains is not just a motto—it's our commitment to providing games that challenge your cognitive prowess and fuel your competitive spirit. Embrace the challenge, strategize like a tycoon, and celebrate your victories. Join us on this journey of intellectual curiosity and let's build business empires together. Welcome to the new era of gaming, where intelligence meets entrepreneurship.
                                        </p>
                                        <a href="#games">
                                            <v-btn
                                                class="mt-4"
                                                size="large"
                                                prepend-icon="mdi-arrow-right"
                                                color="secondary"
                                                >SEE GAMES</v-btn
                                            >
                                        </a>
                                    </div>
                                </v-col>
                                <v-col class="d-flex justify-end align-center r-hide">
                                    <img
                                        src="assets_images_slide.webp"
                                        height="500"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </section>
                <section class="bg-section py-6">
                    <v-container style="max-width: 1320px">
                        <h1 id="games" class="text-h4 text-secondary mb-4">
                            Games
                        </h1>
                        <v-card class="rounded-lg mb-8" :elevation="3">
                            <v-row no-gutters>
                                <v-col class="d-flex">
                                    <img src="junkyard.webp" class="game-img" height="350" />
                                </v-col>
                                <v-col
                                    class="pa-4 d-flex flex-column justify-space-between"
                                >
                                    <div>
                                        <h1 class="text-h3 mb-4">
                                            Junkyard Tycoon
                                        </h1>
                                        <p class="text-body-2">
                                            "Junkyard Tycoon is a fantastic
                                            Simulation game where you can
                                            establish your own kingdom in your
                                            own car junkyard, buy cars or car
                                            parts, dismantle and sell car parts
                                            to increase your wealth. Among the
                                            seemingly useless scrap car parts,
                                            you can grow your company day by
                                            day, and you can establish your own
                                            scrap kingdom. All you need to do is
                                            continue to buy and sell cars or car
                                            parts. You can buy vehicles at scrap
                                            prices, replace necessary parts, and
                                            then sell them for much higher
                                            prices to make a considerable
                                            fortune. This game is an excellent
                                            business simulation game where you
                                            can test your business acumen and
                                            entrepreneurial spirit."
                                        </p>
                                    </div>
                                    <div class="d-flex game-buttons">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=junkyardtycoon.business.tycoon.game&utm_source=bizsimcom&utm_medium=gamebutton&utm_campaign=websitepromotion"
                                            class="store me-3"
                                        >
                                            <v-icon size="32"
                                                >mdi-google-play</v-icon
                                            >
                                            <div class="text">
                                                <p>Available On</p>
                                                <p class="name">Google Play</p>
                                            </div>
                                        </a>
                                        <a
                                            href="https://apps.apple.com/no/app/junkyard-tycoon-araba-ticareti/id1447610640?utm_source=bizsimcom&utm_medium=gameimage&utm_campaign=websitepromotion"
                                            class="store me-3"
                                        >
                                            <v-icon size="32">mdi-apple</v-icon>
                                            <div class="text">
                                                <p>Available On</p>
                                                <p class="name">App Store</p>
                                            </div>
                                        </a>
                                        <a
                                            href="https://junkyardtycoon.com/"
                                            class="store me-3"
                                        >
                                            <v-icon size="32">mdi-web</v-icon>
                                            <div class="text">
                                                <p class="name">Go to Game</p>
                                            </div>
                                        </a>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card class="rounded-lg" :elevation="3">
                            <v-row no-gutters>
                                <v-col class="d-flex">
                                    <img src="roc.webp" class="game-img" height="350" />
                                </v-col>
                                <v-col
                                    class="pa-4 d-flex flex-column justify-space-between"
                                >
                                    <div>
                                        <h1 class="text-h3 mb-4">
                                            Rise of Companies
                                            <v-chip color="info">SOON</v-chip>
                                        </h1>
                                        <p class="text-body-2">
                                            Rise of Companies is an immersive
                                            business simulation and tycoon game
                                            on Google Play Store. As an
                                            ambitious entrepreneur, you will
                                            start and manage your own company,
                                            build a global business empire, and
                                            experience the thrill of climbing
                                            the social ladder. With a variety of
                                            industries to choose from and
                                            abundant opportunities to seize, you
                                            will become a true virtual CEO in
                                            this industry tycoon simulator.
                                        </p>
                                    </div>
                                    <div class="d-flex game-buttons">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.BizSim.RiseofCompanies&utm_source=bizsimcom&utm_medium=gameimage&utm_campaign=websitepromotion"
                                            class="store me-3"
                                        >
                                            <v-icon size="32"
                                                >mdi-google-play</v-icon
                                            >
                                            <div class="text">
                                                <p>Available On</p>
                                                <p class="name">Google Play</p>
                                            </div>
                                        </a>
                                        <a
                                            href="https://riseofcompanies.com/"
                                            class="store me-3"
                                        >
                                            <v-icon size="32">mdi-web</v-icon>
                                            <div class="text">
                                                <p class="name">Go to Game</p>
                                            </div>
                                        </a>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-container>
                </section>
                <section class="py-6">
                    <v-container style="max-width: 1320px">
                        <h1 id="team" class="text-h4 text-secondary mb-10">
                            Team
                        </h1>
                        <v-row no-gutters>
                            <v-col
                                class="d-flex flex-column align-center justify-center"
                            ><a
                            href="https://www.linkedin.com/in/askinceyhan/"
                            class="text-white"
                            style="text-decoration: none"
                            >
                                <img src="askinceyhan.webp" height="64" width="64" />
                                <h1 class="text-h5 team-name">Aşkın Ceyhan</h1>
                                <p class="text-caption">CEO & Founder</p>
                                </a>
                            </v-col>
                            <v-col
                                class="d-flex flex-column align-center justify-center"
                            >
                                <v-icon size="64">mdi-account</v-icon>
                                <h1 class="text-h5 team-name">Gencer Ger</h1>
                                <p class="text-caption">Game Developer</p>
                            </v-col>
                            <v-col
                                class="d-flex flex-column align-center justify-center"
                            >
                                <v-icon size="64">mdi-account</v-icon>
                                <h1 class="text-h5 team-name">Recep Kefeli</h1>
                                <p class="text-caption">Backend Developer</p>
                            </v-col>
                            
                        </v-row>
                    </v-container>
                </section>
                <section class="bg-section py-6">
                    <v-container style="max-width: 1320px">
                        <h1 id="aboutus" class="text-h4 text-secondary mb-4">
                            About Us
                        </h1>
                        <v-card>
                            <v-card-text class="d-flex abus-wrap">
                                <img
                                    src="bizsimlogocube.webp"
                                    class="me-4"
                                    height="72"
                                    alt=""
                                />
                                <div>
                                    <h1 class="text-h5">BizSim Game Studios</h1>
                                    <p>
                                        Founded in January 2020, BizSim Game
                                        Studio has started to make great
                                        achievements in the very rare Business
                                        Simulation Games made in the world and
                                        is now focused on creating a sustainable
                                        game development ecosystem globally. If
                                        you want to join the BizSim Game Studios
                                        team, apply for a position that suits
                                        you right away.
                                    </p>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </section>
                <v-footer class="text-center d-flex flex-column" id="contact">
                    <div class="my-4">
                        <a
                            href="https://bizsim.com/BizSimPrivacyPolicy.html"
                            class="text-white"
                            style="text-decoration: none"
                            >Privacy Policy</a
                        >
                    </div>
                    <div>
                        <a
                            v-for="(social, index) in socials"
                            :key="index"
                            :href="social.url"
                        >
                            <v-btn
                                class="mx-4"
                                color="white"
                                :icon="social.icon"
                                variant="text"
                            ></v-btn>
                        </a>
                    </div>
                    <v-divider></v-divider>
                    <div>
                        {{ new Date().getFullYear() }} —
                        <strong>BizSim Game Studios</strong>
                    </div>
                </v-footer>
            </v-main>
        </v-layout>
    </v-app>
</template>

<script>
export default {
    name: "App",
    data: () => ({
        drawer: false,
        socials: [
            { url: "mailto:info@bizsim.com", icon: "mdi-email" },
            {
                url: "https://www.instagram.com/bizsimgamestudios/",
                icon: "mdi-instagram",
            },
            {
                url: "https://play.google.com/store/apps/dev?id=7070443063864759188",
                icon: "mdi-google-play",
            },
        ],
    }),
};
</script>
