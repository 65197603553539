import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import "@/assets/css/main.css";

import VueParticles from 'vue-particles';

loadFonts()

createApp(App)
  .use(vuetify)
  .use(VueParticles)
  .mount('#app')
